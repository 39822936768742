<template>
  <div class="home">
    <!--    <elMenu></elMenu>-->
    <!--    <el-menu-->
    <!--        router-->
    <!--        :default-active="activeIndex2"-->
    <!--        class="el-menu-demo"-->
    <!--        mode="horizontal"-->
    <!--        @select="handleSelect"-->
    <!--        background-color="rgba(0, 0, 0, 0.2)"-->
    <!--        text-color="#fff"-->
    <!--        active-text-color="#ffd04b">-->
    <!--      <el-menu-item>-->
    <!--        <img src="../../public/images/home/logo.png" width="176px" height="48px">-->
    <!--      </el-menu-item>-->
    <!--      <el-menu-item index="1">首页</el-menu-item>-->
    <!--      <el-submenu index="2">-->
    <!--        <template slot="title">核心板</template>-->
    <!--        <el-menu-item index="2-1">低功耗T31ZL+Hi3861-->
    <!--        </el-menu-item>-->
    <!--        <el-menu-item index="2-2">低功耗T31ZX+Hi3861</el-menu-item>-->
    <!--        <el-menu-item index="2-3">长电T31N+Hi3881</el-menu-item>-->
    <!--        <el-menu-item index="2-4">长电T31X+Hi3881</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--      <el-submenu index="3">-->
    <!--        <template slot="title">Mini电池摄像机</template>-->
    <!--        <el-menu-item index="3-1">低功耗4G-BMG2</el-menu-item>-->
    <!--        <el-menu-item index="3-2">低功耗WiFi-BMW2</el-menu-item>-->
    <!--        <el-menu-item index="3-3">长电4G-MF1</el-menu-item>-->
    <!--        <el-menu-item index="3-4">长电小方WiFi-VO14T</el-menu-item>-->
    <!--        <el-menu-item index="3-5">长电小圆WiFi-VO15T/A9T</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--      <el-submenu index="4">-->
    <!--        <template slot="title">低功耗门铃</template>-->
    <!--        <el-menu-item index="4-1">横版门铃-DW4 2M/4M</el-menu-item>-->
    <!--        <el-menu-item index="4-2">竖版门铃-DW4 2M/4M</el-menu-item>-->
    <!--        <el-menu-item index="4-3">防水门铃-DW5 2M/4M</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--      <el-submenu index="5">-->
    <!--        <template slot="title">低功耗电池摄像机</template>-->
    <!--        <el-menu-item index="5-1">低功耗4G-BG2 2M/4MB1</el-menu-item>-->
    <!--        <el-menu-item index="5-2">低功耗4G-BG2 2M/4M B2</el-menu-item>-->
    <!--        <el-menu-item index="5-3">低功耗WiFi-BW2 2M/4M B1</el-menu-item>-->
    <!--        <el-menu-item index="5-4">低功耗WiFi-BW2 2M/4M B2</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--      <el-submenu index="6">-->
    <!--        <template slot="title">低功耗枪机/球机</template>-->
    <!--        <el-menu-item index="6-1">低功耗4G-38*38 2M/4M</el-menu-item>-->
    <!--        <el-menu-item index="6-2">低功耗4G-小黄人版2M/4M</el-menu-item>-->
    <!--        <el-menu-item index="6-3">低功耗WiFi-38*38 2M/4M</el-menu-item>-->
    <!--        <el-menu-item index="6-4">低功耗WiFi-小黄人版2M/4M</el-menu-item>-->
    <!--        <el-menu-item index="6-5">长电4G-38*38 2M/4M</el-menu-item>-->
    <!--        <el-menu-item index="6-6">长电4G-小黄人版2M/4M</el-menu-item>-->
    <!--        <el-menu-item index="6-7">长电WiFi-38*38 2M/4M</el-menu-item>-->
    <!--        <el-menu-item index="6-8">长电WiFi-小黄人版 2M/4M</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--      <el-submenu index="7">-->
    <!--        <template slot="title">低功耗云台机</template>-->
    <!--        <el-menu-item index="7-1">低功耗摇头机 4G/WiFi</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--      <el-submenu index="8">-->
    <!--        <template slot="title">VeeCam</template>-->
    <!--        <el-menu-item index="8-1">SDK开发包</el-menu-item>-->
    <!--        <el-menu-item index="8-2">APP下载</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--      <el-submenu index="9">-->
    <!--        <template slot="title">平台方案</template>-->
    <!--        <el-menu-item index="9-1">4G流量分成</el-menu-item>-->
    <!--        <el-menu-item index="9-2">云存储分成</el-menu-item>-->
    <!--        <el-menu-item index="9-3">服务器分成</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--      <el-submenu index="10">-->
    <!--        <template slot="title">关于我们</template>-->
    <!--        <el-menu-item index="10-1" route="companyProfile">关于公司</el-menu-item>-->
    <!--        <el-menu-item index="10-2">联系我们</el-menu-item>-->
    <!--        <el-menu-item index="10-3">加入我们</el-menu-item>-->
    <!--        <el-menu-item index="10-4">隐私政权策</el-menu-item>-->
    <!--      </el-submenu>-->
    <!--    </el-menu>-->
    <!--    轮播-->
    <el-carousel :interval="4000" height="930px">
      <el-carousel-item>
        <img src="../../public/images/home/carousel/1.jpg" width="100%" height="100%" @click="dgh_4gb2">
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../public/images/home/carousel/2.jpg" width="100%" height="100%" @click="hmbl_dw4">
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../public/images/home/carousel/3.jpg" width="100%" height="100%" @click="dghwifi_bmw2">
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../public/images/home/carousel/4.jpg" width="100%" height="100%" @click="cdxfwifi">
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../public/images/home/carousel/5.jpg" width="100%" height="100%" @click="ytjwifi">
      </el-carousel-item>
    </el-carousel>

    <!--    content1-->
    <!--    <div id="flow" class="content1">-->
    <!--      <div class="content1_title">-->
    <!--        <span style="font-size: 35px;">0基础产品智能化升级</span>-->
    <!--        <p style="font-size: 26px; color: #666;">最快1天一站式解决视频产品智能化升级</p>-->
    <!--      </div>-->
    <!--      <el-row>-->
    <!--        <el-col :span="8" :push="5"><img src="../../public/images/home/znhsj.jpg"></el-col>-->
    <!--        <el-col :span="8" :push="5">-->
    <!--          <div class="container1_form">-->
    <!--            <h1>留下您的信息体验智能化升级</h1>-->
    <!--            <el-form :model="information" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">-->
    <!--              <el-form-item label="公司名称" prop="company">-->
    <!--                <el-input v-model="information.company" style="width:300px"></el-input>-->
    <!--              </el-form-item>-->
    <!--              <el-form-item label="联系人姓名" prop="name">-->
    <!--                <el-input v-model="information.name" style="width:300px"></el-input>-->
    <!--              </el-form-item>-->
    <!--              <el-form-item label="联系电话" prop="phone">-->
    <!--                <el-input v-model="information.phone" style="width:300px"></el-input>-->
    <!--              </el-form-item>-->
    <!--              <el-form-item label="联系邮箱" prop="mailbox">-->
    <!--                <el-input v-model="information.mailbox" style="width:300px"></el-input>-->
    <!--              </el-form-item>-->
    <!--              <el-form-item>-->
    <!--                <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
    <!--                <el-button @click="resetForm('ruleForm')">重置</el-button>-->
    <!--              </el-form-item>-->
    <!--            </el-form>-->
    <!--            <p class="online_contact"><a style="color:red" href="tencent://message/?uin=2850579859">在线联系</a></p>-->
    <!--          </div>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </div>-->
    <!--    content2-->
    <!--    <div class="content2">-->
    <!--      <div class="content1_title">-->
    <!--        <span style="font-size: 35px;">极速落地·智能从无到有一站完成</span>-->
    <!--        <p style="font-size: 26px; color: #666;">1对1专业服务，搭配急速智能解决方案，更有免费Demo赠送</p>-->
    <!--        <img src="../../public/images/home/jsld.jpg" style="padding-top: 40px">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    content3-->
    <!--    <div id="target" class="content3">-->
    <!--      <div class="content3_title">-->
    <!--        <span style="font-size: 35px;">维拍六大优势·解决产品智能化难题</span>-->
    <!--      </div>-->
    <!--      <div class="content3_img">-->
    <!--        <img src="../../public/images/home/qynt1.jpg">-->
    <!--        <img src="../../public/images/home/qynt2.jpg">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    content4-->
    <!--    <div id="distribution" class="content4">-->
    <!--      <div class="content1_title">-->
    <!--        <span style="font-size: 35px;">尖端研发技术与顶级开发方式</span>-->
    <!--        <p style="font-size: 26px; color: #666;">服务全球30000+企业智能化升级</p>-->
    <!--      </div>-->
    <!--      <div class="content3_img">-->
    <!--        <img src="../../public/images/home/fwqq.jpg">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    content5-->
    <!--    <div class="content5">-->
    <!--      <div class="content5_title">-->
    <!--        <span style="font-size: 35px;">维拍可实现全品类视频产品、传统固件智能升级，30000+款智能 产品可实现语音对接</span>-->
    <!--        <p style="font-size: 26px; color: #AEB9C7;">通过平台开发的智能产品可覆盖中国、美国、欧洲、澳洲、中东等在内的全球190+国家， 产品可触达亿万级消费人群</p>-->
    <!--      </div>-->
    <!--      <div class="content5_img">-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/1.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/2.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/3.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/4.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/5.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/6.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/7.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/8.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/9.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/10.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/11.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/12.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/13.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/14.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/15.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/16.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/17.png"></div>-->
    <!--        <div class="syproductlist1"><img src="../../public/images/home/18.png"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    content6-->
    <!--    <div class="content6">-->
    <!--      <div class="content1_title">-->
    <!--        <span style="font-size: 35px;">合作伙伴</span>-->
    <!--        <p style="font-size: 26px; color: #666;">越来越多的客户选择和信赖维拍</p>-->
    <!--      </div>-->
    <!--      <div class="client_list">-->
    <!--        <div><img src="../../public/images/home/partner/1.jpg" width="145px" height="73px"></div>-->
    <!--        <div><img src="../../public/images/home/partner/2.jpg" width="145px" height="73px"></div>-->
    <!--        <div><img src="../../public/images/home/partner/3.jpg" width="145px" height="73px"></div>-->
    <!--        <div><img src="../../public/images/home/partner/4.jpg" width="145px" height="73px"></div>-->
    <!--        <div><img src="../../public/images/home/partner/5.jpg" width="145px" height="73px"></div>-->
    <!--        <div><img src="../../public/images/home/partner/6.jpg" width="145px" height="73px"></div>-->
    <!--        <div><img src="../../public/images/home/partner/7.jpg" width="145px" height="73px"></div>-->
    <!--        <div><img src="../../public/images/home/partner/8.jpg" width="145px" height="73px"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    content7-->
    <!--    <div class="content7">-->
    <!--      <h1>全球智能化平台</h1>-->
    <!--      <p>拓展您的业务,展示您的风采,或许只需要尝试一下新的想法。</p>-->
    <!--      <div class="content7_f">-->
    <!--        <div class="content7_btn">-->
    <!--          <a href="mailto:hello@niceui.cn">咨询我们</a>-->
    <!--        </div>-->
    <!--        <div class="content7_btn">-->
    <!--          <router-link to="companyProfile">了解更多</router-link>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    home_foot-->
    <!--    <div class="home_foot">-->
    <!--      <el-row style="color:white">-->
    <!--        <el-col :span="2" :push="3">-->
    <!--          <ul class="home_foot_ul">-->
    <!--            <li>首页</li>-->
    <!--            <li @click="scro">优势介绍</li>-->
    <!--            <li @click="flow">对接流程</li>-->
    <!--            <li>合作伙伴</li>-->
    <!--            <li @click="distribution">服务器分布</li>-->
    <!--          </ul>-->
    <!--        </el-col>-->
    <!--        <el-col :span="2" :push="4">-->
    <!--          <ul>-->
    <!--            <li>产品方案</li>-->
    <!--            <li @click="eye4">Eye4云平台</li>-->
    <!--            <li @click="hardwareScheme">硬件方案</li>-->

    <!--          </ul>-->
    <!--        </el-col>-->
    <!--        <el-col :span="2" :push="5">-->
    <!--          <ul>-->
    <!--            <li>技术支持</li>-->

    <!--          </ul>-->
    <!--        </el-col>-->
    <!--        <el-col :span="2" :push="6">-->
    <!--          <ul>-->
    <!--            <li>关于我们</li>-->
    <!--            <li @click="companyProfile">公司简介</li>-->
    <!--            <li @click="Join">加入我们</li>-->
    <!--            <li @click="privacy">隐私政策</li>-->
    <!--          </ul>-->
    <!--        </el-col>-->
    <!--        <el-col :span="4" :push="7">-->
    <!--          <ul>-->
    <!--            <li>联系我们</li>-->
    <!--            <li><a href="mailto:hello@niceui.cn">邮箱：sales@veepai.com</a></li>-->
    <!--            <li>手机：15817227053</li>-->
    <!--            <li>座机：0755-86641624</li>-->
    <!--            <li>Q Q：2850579859</li>-->
    <!--          </ul>-->
    <!--        </el-col>-->
    <!--        <el-col :span="2" :push="6">-->
    <!--          <ul>-->
    <!--            <li>关注我们</li>-->
    <!--            <li><img src="../../public/images/home/w.jpg" width="100px" height="100px"></li>-->
    <!--          </ul>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--      <div class="foot_bottom">-->
    <!--        <p>-->
    <!--          Copyright © 2018-2021 深圳市维拍物联智能技术有限公司 粤ICP备19104755号-->
    <!--          <a href="https://www.cnzz.com/stat/website.php?web_id=1259561122"><img style="padding-left: 10px"-->
    <!--                                                                                 src="https://icon.cnzz.com/img/pic1.gif"></a>-->
    <!--          <a href="https://szcert.ebs.org.cn/48fd5d47-f3dc-4911-9015-01df59d0dc34"><img style="padding-left: 10px"-->
    <!--                                                                                        width="24px" height="34px"-->
    <!--                                                                                        src="../../public/images/home/govlcon.gif">-->
    <!--          </a>-->

    <!--        </p>-->
    <!--&lt;!&ndash;        <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>&ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  侧边导航-->
    <foot></foot>
    <navigation></navigation>
    <!--    <div>-->
    <!--      <ul class="side-pannel">-->
    <!--        <li>-->
    <!--          <img src="../../public/images/home/qq.png">-->
    <!--        </li>-->
    <!--          <el-popover-->
    <!--              placement="left"-->
    <!--              title="咨询电话/微信"-->
    <!--              trigger="hover"-->

    <!--              content="15817227053">-->
    <!--            <li slot="reference">  <img src="../../public/images/home/电话.png"></li>-->
    <!--          </el-popover>-->
    <!--        <li>-->
    <!--          <img src="../../public/images/home/微信.png">-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->
  </div>
</template>

<script>
import navigation from '../../src/components/navigation'
import elMenu from '../../src/components/elMenu'
import foot from '../../src/components/foot'
export default {
  name: 'Home',
  components: {
    navigation,elMenu,foot
  },
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      labelPosition: 'right',
      // 信息提交表单
      information: {
        company: '',
        name: '',
        phone: '',
        mailbox: ''
      },
      // 表单认证
      rules: {
        name: [
          {required: true, message: '请输入您的名称', trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        company: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入您的电话', trigger: 'blur'},
        ],
        mailbox: [
          {required: true, message: '请输入您的邮箱', trigger: 'blur'},
        ],

      }

    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    scro() {
      target.scrollIntoView();
    },
    flow() {
      flow.scrollIntoView();
    },
    distribution() {
      distribution.scrollIntoView();
    },
    eye4() {
      this.$router.push('/Eye4')
    },
    hardwareScheme(){
      this.$router.push('/hardwareScheme')
    },
    companyProfile(){
      this.$router.push('/companyProfile')
    },
    Join(){
      this.$router.push('/Join')
    },
    privacy(){
      this.$router.push('/privacy')
    },
    dgh_4gb2(){
      this.$router.push('/dgh4G-BG2-B2')
    },
    hmbl_dw4(){
      this.$router.push('/hbml-DW4')
    },
    dghwifi_bmw2(){
      this.$router.push('/dghWiFi-BMW2')
    },
    cdxfwifi(){
      this.$router.push('/cdxfWiFi-VO14T')
    },
    ytjwifi(){
      this.$router.push('/dghtyj--WiFi')

    }


  }
}
</script>
<style scoped>

/*.el-menu-demo {*/
/*  width: 100%;*/
/*  z-index: 111100;*/
/*  position: fixed;*/
/*  left: 0;*/
/*  top: 0;*/
/*}*/

/deep/ .el-menu.el-menu--horizontal {
  border-bottom: 0;
}

/deep/ .el-menu {
  padding-left: 149px;
}

.content1 {
  width: 100%;
  height: 889px;
  background-color: #F7F7F7;

}

.content1_title {
  text-align: center;
  padding-top: 70px;
}

.content3_title {
  text-align: center;
  padding-top: 40px;
  color: white;
}

.container1_form {
  width: 525px;
  height: 505px;
  background: #ffffff;
  border: 1px #ececec solid;
  border-radius: 10px;
  margin-top: 56px;
}

.container1_form h1 {
  text-align: center;
  font-size: 22px;
  height: 120px;
  line-height: 120px;
  font-weight: 200;
}

.online_contact {
  text-align: center;
  color: #1E99DF;
  font-size: 12px;
  padding-top: 20px;
}

.content2 {
  height: 905px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 70px;
}

.content3 {
  height: 905px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 40px;
  background-color: black;
}

.content3_img {
  padding-top: 70px;
  text-align: center;
}

.content4 {
  height: 1105px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 70px;
}

.content5 {
  height: 885px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 70px;
  background-color: #345C82;
}

.content5_title {
  text-align: center;
  padding-top: 40px;
  color: white;
}

.content5_img {
  width: 1224px;
  height: 800px;
  margin: 30px auto;

}

.syproductlist1 {
  width: 180px;
  height: 180px;
  margin: 10px 12px;
  float: left;
  background: #ffffff;
}

.content6 {
  height: 375px;
  width: 100%;
}

.client_list {
  width: 80%;
  padding-top: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.client_list div {
  margin: 5px;
}

.content7 {
  background: url(../../public/images/home/download.jpg) no-repeat center top #163e59;
  background-attachment: fixed;
  width: 100%;
  height: 340px;
  background-size: cover;
  text-align: center;
  color: #fff;
  overflow: hidden;
}

.content7 h1 {
  line-height: 38px;
  text-align: center;
  font-size: 28px;
  margin-top: 80px;
}

.content7 p {
  font-size: 14px;
  color: #e2e2e2;
  line-height: 28px;
  width: 840px;
  margin: 10px auto;
  text-align: center;
  padding-bottom: 30px;
}

.content7_btn {
  width: 150px;
  margin: 10px;
  background: #019bff;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
  height: 46px;
  line-height: 46px;
}

.content7_btn:hover {
  cursor: pointer;
  background-color: #2977FF;
  transition: width 2s;
}

.content7_f {
  justify-content: center;
  display: flex;
}

.home_foot {
  color: white;
  height: 380px;
  width: 100%;
  background: #1f1f21;
  overflow: hidden;
  padding-bottom: 0px;
}

.home_foot ul {
  list-style: none;
  padding-top: 50px;
}

.home_foot ul li:nth-child(1) {
  font-size: 18px;
  cursor: auto;

}

.home_foot ul li:nth-child(1):hover {

  color: white;
}

.home_foot ul li {
  padding-top: 10px;
  font-size: 12px;
  cursor: pointer;
}

.home_foot ul li:hover {
  color: #019BFF;
}

.foot_bottom {
  margin-top: 80px;
  color: #7e7e7e;
  text-align: center;
  clear: both;
  width: 100%;
  overflow: hidden;
  border-top: 1px #7e7e7e solid;
  height: 60px;
}

.side-pannel {
  list-style: none;
  position: fixed;
  width: 50px;
  bottom: 75px;
  right: 33px;
  z-index: 1000;
}
a{
  color: white;
  text-decoration:none
}
a:hover{
  color: #019BFF;
}
img:hover{
  cursor: pointer;
}
img{
  width:100%;height:100%;position:absolute;z-index:-1;
}

</style>