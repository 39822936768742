<template>
  <div>
    <el-backtop target=".home">
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <div>
      <ul class="side-pannel">
        <li>
          <a href="tencent://message/?uin=2850579859"><img src="../../public/images/home/qq.png"></a>
        </li>
        <el-popover
            placement="left"
            title="咨询电话/微信"
            trigger="hover"
            content="15817227053">
          <li slot="reference">  <img src="../../public/images/home/电话.png"></li>
        </el-popover>
        <el-popover
            placement="left"
            title="微信公众号"
            trigger="hover"
            >
          <img src="../../public/images/home/w.jpg">
          <li slot="reference"><img src="../../public/images/home/微信.png"></li>
        </el-popover>

      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "navigation"
}
</script>

<style scoped>
/*.home {*/
/*  height: 100vh;*/
/*  overflow: scroll;*/
/*}*/
.side-pannel {
  list-style: none;
  position: fixed;
  width: 50px;
  bottom: 75px;
  right: 33px;
  z-index: 1000;
}
</style>