<template>
  <div class="foot">
    <div class="home_foot">
      <el-row style="color:white">
        <el-col :span="2" :push="3">
          <ul class="home_foot_ul">
            <li>首页</li>
            <li @click="scro"><router-link to="/dgh4G-BG2-B2">低功耗4G-BG2 B2</router-link></li>
            <li @click="flow"><router-link to="/hbml-DW4">横版门铃-DW4</router-link></li>
            <li><router-link to="/dghWiFi-BMW2">低功耗WiFi-BMW2</router-link></li>
            <li @click="distribution"><router-link to="/cdxfWiFi-VO14T">长电小方WiFi-VO14T</router-link></li>
            <li @click="distribution"><router-link to="/dghtyj--WiFi">低功耗摇头机 WiFi</router-link></li>
          </ul>
        </el-col>
        <el-col :span="2" :push="4">
          <ul>
            <li>产品方案</li>
            <li @click="eye4">VeePai云平台</li>
            <li @click="hardwareScheme">硬件方案</li>

          </ul>
        </el-col>
        <el-col :span="2" :push="5">
          <ul>
            <li>小鹰看看</li>
            <li @click="sdk">SDK开发包</li>
            <li @click="app">APP下载</li>
            <li @click="otherSoftware">其他软件下载</li>


          </ul>
        </el-col>
        <el-col :span="2" :push="6">
          <ul>
            <li>关于我们</li>
            <li @click="companyProfile">公司简介</li>
            <li @click="Join">加入我们</li>
            <li @click="privacy">隐私政策</li>
          </ul>
        </el-col>
        <el-col :span="4" :push="7">
          <ul>
            <li>联系我们</li>
            <li><a href="mailto:hello@niceui.cn">邮箱：sales@veepai.com</a></li>
            <li>手机：15817227053</li>
            <li>座机：0755-86641624</li>
            <li>Q Q：2850579859</li>
          </ul>
        </el-col>
        <el-col :span="2" :push="6">
          <ul>
            <li>关注我们</li>
            <li><img src="../../public/images/home/w.jpg" width="100px" height="100px"></li>
          </ul>
        </el-col>
      </el-row>
      <div class="foot_bottom">
        <p>
          Copyright © 2018-2022 深圳市维拍物联智能技术有限公司 粤ICP备19104755号
          <a href="https://www.cnzz.com/stat/website.php?web_id=1259561122"><img style="padding-left: 10px"
                                                                                 src="https://icon.cnzz.com/img/pic1.gif"></a>
          <a href="https://szcert.ebs.org.cn/48fd5d47-f3dc-4911-9015-01df59d0dc34"><img style="padding-left: 10px"
                                                                                        width="24px" height="34px"
                                                                                        src="../../public/images/home/govlcon.gif">
          </a>

        </p>
        <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot",
  methods: {
    scro() {
      // target.scrollIntoView();
    },
    flow() {
      // flow.scrollIntoView();
    },
    distribution() {
      // distribution.scrollIntoView();
    },
    eye4() {
      this.$router.push('/Eye4')
    },
    hardwareScheme(){
      this.$router.push('/hardwareScheme')
    },
    companyProfile(){
      this.$router.push('/companyProfile')
    },
    Join(){
      this.$router.push('/Join')
    },
    privacy(){
      this.$router.push('/privacy')
    },
    sdk(){
      this.$router.push('/sdk')
    },
    app(){
      this.$router.push('/app')
    },
    otherSoftware(){
      this.$router.push('/otherSoftware')
    }
  }
}
</script>

<style scoped>
.foot{
  margin-top: -5px;
}
.home_foot {
  color: white;
  height: 380px;
  width: 100%;
  background: #1f1f21;
  overflow: hidden;
  padding-bottom: 0px;
}

.home_foot ul {
  list-style: none;
  padding-top: 50px;
}

.home_foot ul li:nth-child(1) {
  font-size: 18px;
  cursor: auto;

}

.home_foot ul li:nth-child(1):hover {

  color: white;
}

.home_foot ul li {
  padding-top: 10px;
  font-size: 12px;
  cursor: pointer;
}

.home_foot ul li:hover {
  color: #019BFF;
}

.foot_bottom {
  margin-top: 55px;
  color: #7e7e7e;
  text-align: center;
  clear: both;
  width: 100%;
  overflow: hidden;
  border-top: 1px #7e7e7e solid;
  height: 70px;
}
a{
  color: white;
  text-decoration:none
}
a:hover{
  color: #019BFF;
}
</style>