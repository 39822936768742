import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Eye4',
    name: 'Eye4',
    component: () => import('../views/productProgram/Eye4')
  },
  {
    path: '/hardwareScheme',
    name: 'hardwareScheme',
    component: () => import('../views/productProgram/hardwareScheme')
  }
  ,
  {
    path: '/companyProfile',
    name: 'companyProfile',
    component: () => import('../views/AboutUs/companyProfile')
  },
  {
    path: '/Join',
    name: 'Join',
    component: () => import('../views/AboutUs/Join')
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('../views/AboutUs/contactus')
  }
  ,
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/AboutUs/privacy')
  }
  ,
  {
    path: '/pet',
    name: 'pet',
    component: () => import('../views/IPC/pet/pet')
  }
  ,
  {
    path: '/liveStreaming',
    name: 'liveStreaming',
    component: () => import('../views/IPC/liveStreaming/liveStreaming')
  }
  ,
  {
    path: '/bluetooth',
    name: 'bluetooth',
    component: () => import('../views/IPC/bluetooth/bluetooth')
  }
  ,
  {
    path: '/lowPowerConsumption',
    name: 'lowPowerConsumption',
    component: () => import('../views/IPC/lowPowerConsumption/lowPowerConsumption')
  }
  ,
  {
    path: '/solarEnergy',
    name: 'solarEnergy',
    component: () => import('../views/IPC/lowPowerConsumption/solarEnergy')
  }
  ,
  {
    path: '/bigcell',
    name: 'bigcell',
    component: () => import('../views/IPC/lowPowerConsumption/bigcell')
  }
  ,
  {
    path: '/littlecell',
    name: 'littlecell',
    component: () => import('../views/IPC/lowPowerConsumption/littlecell')
  }
  ,
  {
    path: '/network',
    name: 'network',
    component: () => import('../views/IPC/network/network')
  }
  ,
  {
    path: '/light1',
    name: 'light1',
    component: () => import('../views/IPC/network/light1')
  }
  ,
  {
    path: '/light2',
    name: 'light2',
    component: () => import('../views/IPC/network/light2')
  }
  ,
  {
    path: '/cd4G-MF1',
    name: '长电4G-MF1',
    component: () => import('../views/Mini/cd4G-MF1')
  }
  ,
  {
    path: '/mn4G-BMG2',
    name: 'mn4G-BMG2',
    component: () => import('../views/Mini/mn4G-BMG2')
  }
  ,
  {
    path: '/dghWiFi-BMW2',
    name: 'dghWiFi-BMW2',
    component: () => import('../views/Mini/dghWiFi-BMW2')
  }
  ,
  {
    path: '/cdxfWiFi-VO14T',
    name: '长电小方WiFi-VO14T',
    component: () => import('../views/Mini/长电小方WiFi-VO14T')
  }
  ,
  {
    path: '/cdxyWiFi',
    name: '长电小圆WiFi',
    component: () => import('../views/Mini/长电小圆WiFi')
  }
  ,
  {
    path: '/hbml-DW4',
    name: '横版门铃-DW4',
    component: () => import('../views/低功耗门铃/横版门铃-DW4')
  }
  ,
  {
    path: '/sbml-DW4',
    name: '竖版门铃-DW4',
    component: () => import('../views/低功耗门铃/竖版门铃-DW4')
  }
  ,
  {
    path: '/fsml-DW5',
    name: '防水门铃-DW5',
    component: () => import('../views/低功耗门铃/防水门铃-DW5')
  }
  ,
  {
    path: '/dgh4G-BG2-B1',
    name: '低功耗4G-BG2-B1',
    component: () => import('../views/低功耗电池摄像机/低功耗4G-BG2-B1')
  }
  ,
  {
    path: '/dgh4G-BG2-B2',
    name: '低功耗4G-BG2-B2',
    component: () => import('../views/低功耗电池摄像机/低功耗4G-BG2-B2')
  }
  ,
  {
    path: '/dghwifi-BW2--B1',
    name: '低功耗wifi-BW2--B1',
    component: () => import('../views/低功耗电池摄像机/低功耗wifi-BW2--B1')
  }
  ,
  {
    path: '/dghwifi-BW2--B2',
    name: '低功耗wifi-BW2--B2',
    component: () => import('../views/低功耗电池摄像机/低功耗wifi-BW2--B2')
  }
  ,
  {
    path: '/dghytj--4G',
    name: '低功耗摇头机--4G',
    component: () => import('../views/低功耗云台机/低功耗摇头机--4G')
  }
  ,
  {
    path: '/dghtyj--WiFi',
    name: '低功耗摇头机--WiFi',
    component: () => import('../views/低功耗云台机/低功耗摇头机--WiFi')
  }
  ,
  {
    path: '/dgh4G-38b',
    name: '低功耗4G-38版',
    component: () => import('../views/低功耗枪机球机/低功耗4G-38版')
  }
  ,
  {
    path: '/dghwifi--38b',
    name: '低功耗wifi--38板',
    component: () => import('../views/低功耗枪机球机/低功耗wifi--38板')
  }
  ,
  {
    path: '/dgh4Gxhr',
    name: '低功耗4G小黄人',
    component: () => import('../views/低功耗枪机球机/低功耗4G小黄人')
  }
  ,
  {
    path: '/dghwifixhr',
    name: '低功耗wifi小黄人',
    component: () => import('../views/低功耗枪机球机/低功耗wifi小黄人')
  }
  ,
  {
    path: '/cd4G--38b',
    name: '长电4G--38版',
    component: () => import('../views/低功耗枪机球机/长电4G--38版')
  }
  ,
  {
    path: '/cdwifi--38b',
    name: '长电wifi--38版',
    component: () => import('../views/低功耗枪机球机/长电wifi--38版')
  }
  ,
  {
    path: '/cd4Gxhr',
    name: '长电4G小黄人',
    component: () => import('../views/低功耗枪机球机/长电4G小黄人')
  }
  ,
  {
    path: '/cdwifixhr',
    name: '长电wifi小黄人',
    component: () => import('../views/低功耗枪机球机/长电wifi小黄人')
  }
  ,
  {
    path: '/app',
    name: 'app',
    component: () => import('../views/veecam/app')
  }
  ,
  {
    path: '/sdk',
    name: 'sdk',
    component: () => import('../views/veecam/sdk')
  }
  ,
  {
    path: '/otherSoftware',
    name: 'otherSoftware',
    component: () => import('../views/veecam/otherSoftware')
  }
  ,
  {
    path: '/comingSoon',
    name: 'comingSoon',
    component: () => import('../views/comingSoon')
  }
  ,
  {
    path: '/comingSoon1',
    name: 'comingSoon1',
    component: () => import('../views/敬请期待/comingSoon1')
  }
  ,
  {
    path: '/comingSoon2',
    name: 'comingSoon2',
    component: () => import('../views/敬请期待/comingSoon2')
  }
  ,
  {
    path: '/comingSoon3',
    name: 'comingSoon3',
    component: () => import('../views/敬请期待/comingSoon3')
  }
  ,
  {
    path: '/comingSoon4',
    name: 'comingSoon4',
    component: () => import('../views/敬请期待/comingSoon4')
  }
  ,
  {
    path: '/comingSoon5',
    name: 'comingSoon5',
    component: () => import('../views/敬请期待/comingSoon5')
  }
  ,
  {
    path: '/server',
    name: 'server',
    component: () => import('../views/平台方案/server')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
