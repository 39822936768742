<template>
  <div id="app">
      <elMenu></elMenu>

    <router-view/>
<!--    <a href="#">返回顶部</a>-->
<!--    <foot></foot>-->
<!--    <navigation></navigation>-->
  </div>
</template>

<script>
import elMenu from '../src/components/elMenu'
import foot from '../src/components/foot'
import navigation from '../src/components/navigation'
export default {
  name: "companyProfile",
  components: {
    elMenu,foot,navigation
  },
  data() {
    return {
      isShow: '',
    };
  },
  mounted() {
    window.addEventListener('scroll', this.isScroll,true)
    if (this._isMobile()) {
      window.location.href = 'https://m.veepai.com/';
    }

  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    isScroll() {
      var top = document.body.scrollTop || document.documentElement.scrollTop;
      if (top <= 10) {	// 滚动条滚动距离超过100时
        this.isShow = true
      }
      if (top > 0) {
        this.isShow = false
      }
    }
  },
  destroyed() { //离开这个界面之后，删除，不然会有问题
    window.removeEventListener('scroll', this.isScroll)
  }
}
</script>
<style scoped>
html, body { scroll-behavior:smooth; }
.home {
  position: absolute;
  top: 0;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}
.back-top-container {

}
body{
  margin:auto;
  font-family: "Lantinghei SC", "Open Sans", Arial, "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", "STHeiti", "WenQuanYi Micro Hei", SimSun, sans-serif;

}
</style>
