<template>
  <div>
    <div class="navigation">
      <el-menu
          router
          :default-active="currentpath"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
      >
        <el-menu-item class="sad">
          <img src="../../public/images/home/logo.png" width="146px" height="38px">
        </el-menu-item>
        <el-menu-item index="/" route='/'>首页</el-menu-item>
        <el-submenu>
          <template slot="title">核心板</template>
          <el-menu-item index="/comingSoon" route="comingSoon">低功耗T31ZL+Hi3861</el-menu-item>
          <el-menu-item index="/comingSoon1" route="comingSoon1">低功耗T31ZX+Hi3861</el-menu-item>
          <el-menu-item index="/comingSoon2" route="comingSoon2">长电T31N+Hi3881</el-menu-item>
        </el-submenu>
        <el-submenu index="">
          <template slot="title">Mini电池摄像机</template>
          <el-menu-item index="/mn4G-BMG2" route="mn4G-BMG2">低功耗4G-BMG2</el-menu-item>
          <el-menu-item index="/dghWiFi-BMW2" route="dghWiFi-BMW2">低功耗WiFi-BMW2</el-menu-item>
          <el-menu-item index="/cd4G-MF1" route="cd4G-MF1">长电4G-MF1</el-menu-item>
          <el-menu-item index="/cdxfWiFi-VO14T" route="cdxfWiFi-VO14T">长电小方WiFi-VO14T</el-menu-item>
          <el-menu-item index="/cdxyWiFi" route="cdxyWiFi">长电小圆WiFi-VO15T/A9T</el-menu-item>
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">低功耗门铃</template>
          <el-menu-item index="/hbml-DW4" route="hbml-DW4">横版门铃-DW4 </el-menu-item>
          <el-menu-item index="/sbml-DW4" route="sbml-DW4">竖版门铃-DW4 </el-menu-item>
          <el-menu-item index="/fsml-DW5" route="fsml-DW5">防水门铃-DW5 </el-menu-item>
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">低功耗电池摄像机</template>
          <el-menu-item index="/dgh4G-BG2-B1" route="dgh4G-BG2-B1">低功耗4G-BG2  B1</el-menu-item>
          <el-menu-item index="/dgh4G-BG2-B2" route="dgh4G-BG2-B2">低功耗4G-BG2  B2</el-menu-item>
          <el-menu-item index="/dghwifi-BW2--B1" route="dghwifi-BW2--B1">低功耗WiFi-BW2  B1</el-menu-item>
          <el-menu-item index="/dghwifi-BW2--B2" route="dghwifi-BW2--B2">低功耗WiFi-BW2  B2</el-menu-item>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">低功耗枪机/球机</template>
          <el-menu-item index="/dgh4G-38b" route="dgh4G-38b">低功耗4G-38*38 </el-menu-item>
          <el-menu-item index="/dgh4Gxhr" route="dgh4Gxhr">低功耗4G-小黄人版 </el-menu-item>
          <el-menu-item index="/dghwifi--38b" route="dghwifi--38b">低功耗WiFi-38*38 </el-menu-item>
          <el-menu-item index="/dghwifixhr" route="dghwifixhr">低功耗WiFi-小黄人版 </el-menu-item>
          <el-menu-item index="/cd4G--38b" route="cd4G--38b">长电4G-38*38 </el-menu-item>
          <el-menu-item index="/cd4Gxhr" route="cd4Gxhr">长电4G-小黄人版 </el-menu-item>
          <el-menu-item index="/cdwifi--38b" route="cdwifi--38b">长电WiFi-38*38 </el-menu-item>
          <el-menu-item index="/cdwifixhr" route="cdwifixhr">长电WiFi-小黄人版 </el-menu-item>
        </el-submenu>
        <el-submenu index="7">
          <template slot="title">低功耗云台机</template>
          <el-menu-item index="/dghytj--4G" route="dghytj--4G">低功耗摇头机 4G</el-menu-item>
          <el-menu-item index="/dghtyj--WiFi" route="dghtyj--WiFi">低功耗摇头机 WiFi</el-menu-item>
        </el-submenu>
        <el-submenu index="8">
          <template slot="title">小鹰看看</template>
          <el-menu-item index="/sdk" route="sdk">SDK开发包</el-menu-item>
          <el-menu-item index="/app" route="app">APP下载</el-menu-item>
          <el-menu-item index="/otherSoftware" route="otherSoftware">其他软件下载</el-menu-item>
        </el-submenu>
        <el-submenu index="9">
          <template slot="title">平台方案</template>
          <el-menu-item index="/comingSoon4" route="comingSoon4">4G流量分成</el-menu-item>
          <el-menu-item index="/comingSoon5" route="comingSoon5">云存储分成</el-menu-item>
          <el-menu-item index="/server" route="server">服务器分成</el-menu-item>
        </el-submenu>
        <el-submenu index="10">
          <template slot="title">关于我们</template>
          <el-menu-item index="/companyProfile" route="companyProfile">关于公司</el-menu-item>
          <el-menu-item index="/contactus" route="contactus">联系我们</el-menu-item>
          <el-menu-item index="/Join" route="Join">加入我们</el-menu-item>
          <el-menu-item index="/privacy" route="privacy">隐私权政策</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // activeIndex: '1',
      // activeIndex2: '1',
      currentpath: this.$route.path,
      labelPosition: 'right',
      // 信息提交表单
      information: {
        company: '',
        name: '',
        phone: '',
        mailbox: ''
      },
      // 表单认证
      rules: {
        name: [
          {required: true, message: '请输入您的名称', trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        company: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入您的电话', trigger: 'blur'},
        ],
        mailbox: [
          {required: true, message: '请输入您的邮箱', trigger: 'blur'},
        ],

      }

    };
  },
  watch: {
    '$route'(to, from) {
      this.currentpath = to.path;
    }
  },
  mounted() {
    // activeIndex最终绑定给<el-menu :default-active="activeIndex">
    this.activeIndex = window.location.pathname;
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    scro() {
      target.scrollIntoView();
    },
    flow() {
      flow.scrollIntoView();
    },
    distribution() {
      distribution.scrollIntoView();
    },
    eye4() {
      this.$router.push('/Eye4')
    },
    hardwareScheme() {
      this.$router.push('/hardwareScheme')
    },
    companyProfile() {
      this.$router.push('/companyProfile')
    },
    Join() {
      this.$router.push('/Join')
    },
    privacy() {
      this.$router.push('/privacy')
    },
  }
}
</script>

<style scoped>
.el-menu-item:hover{
  color: #409EFF !important;
  outline: 0 !important;
}

.el-menu:hover {
  outline: 0 !important;
  color: #409EFF !important;
}
.el-menu-item{
  text-align: center;
}

.el-menu-demo {
  /*opacity:0.8;*/
  width:99.1%;
  z-index: 101;
  position: fixed;
  left: 0;
  top: 0;
}

/deep/.el-submenu__title:hover{
  color: #409EFF !important;
}
/deep/.el-submenu__title{
  text-align: center;
}
/deep/[class*=" el-icon-"], [class^=el-icon-] {
  display: none !important;
}

/deep/ .el-menu.el-menu--horizontal {
  border-bottom: 0;
}
</style>